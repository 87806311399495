import clsx from 'clsx';
import {FC, ReactNode, useEffect} from 'react';
import {twMerge} from 'tailwind-merge';
import {zIndex} from '../../external/const';
import {Icon, IconColor, IconSize, IconSvg, IconVariant} from '../Icon/Icon';
import {ButtonAlert, ButtonAlertVariant} from '../Button/ButtonAlert';
import {TestableElement} from '../../external/types';
import {Detached} from '../../internal/components/Detached/Detached';

export enum ToastDirection {
  HORIZONTAL = 'HORIZONTAL',
  VERTICAL = 'VERTICAL',
}

export enum ToastVariant {
  INFO = 'INFO',
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
}

export type ToastProps = {
  id: string;
  title?: string;
  description?: string | ReactNode;
  className?: string;
  variant?: ToastVariant;
  direction?: ToastDirection;
  actionButton?: {
    text: string;
    href?: string;
    onClick?: () => void;
  };
  onClose?: (id: string) => void;
  duration?: false | number;
  detached?: boolean;
} & TestableElement;

export const Toast: FC<ToastProps> = ({
  id,
  title = '',
  description = '',
  actionButton = undefined,
  variant = ToastVariant.INFO,
  className = undefined,
  onClose = () => undefined,
  direction = ToastDirection.HORIZONTAL,
  duration = 20000,
  testId = undefined,
  detached = true,
}) => {
  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout> | null = null;
    if (duration) {
      timeout = setTimeout(() => {
        if (onClose) {
          onClose(id);
        }
      }, duration);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [duration]);

  const styles = {
    component: twMerge(
      clsx(
        `z-[${zIndex.aboveModal}] flex min-h-[48px] rounded-lg overflow-hidden drop-shadow-xl w-fit max-w-[calc(100vw-32px)] bg-white`,
        {
          'min-w-[320px]': direction === ToastDirection.VERTICAL,
        },
      ),
      className,
    ),
    toastIconBox: clsx('h-auto flex justify-center shrink-0', {
      'w-[36px] pt-[14px]': direction === ToastDirection.HORIZONTAL,
      'w-[40px] pt-[12px]': direction === ToastDirection.VERTICAL,
      'bg-blue1-500': variant === ToastVariant.INFO,
      'bg-green-500': variant === ToastVariant.SUCCESS,
      'bg-orange-500': variant === ToastVariant.WARNING,
      'bg-red-500': variant === ToastVariant.ERROR,
    }),
    contentBox: clsx('flex', {
      'max-w-[calc(100%-84px)] pt-[12px]': direction === ToastDirection.HORIZONTAL,
      'flex-col max-w-[calc(100%-80px)] p-[16px] grow': direction === ToastDirection.VERTICAL,
    }),
    title: clsx('grow font-bold whitespace-nowrap text-base leading-[24px]', {
      'px-[8px]': direction === ToastDirection.HORIZONTAL,
    }),
    description: clsx('grow w-full text-sm leading-[16px]', {
      'px-[8px] pt-[4px] whitespace-nowrap overflow-hidden text-ellipsis': direction === ToastDirection.HORIZONTAL,
      'py-[8px]': direction === ToastDirection.VERTICAL,
    }),
    actionButton: clsx('grow', {
      'px-[8px]': direction === ToastDirection.HORIZONTAL,
    }),
    closeIconBox: clsx('pt-[12px] pr-[16px] shrink-0', {
      'pl-[8px]': direction === ToastDirection.HORIZONTAL,
    }),
    closeIcon: 'cursor-pointer',
  };

  const render = () => (
    <div className={styles.component} data-test-element="toast" data-testid={testId}>
      <div className={styles.toastIconBox}>
        <Icon
          color={IconColor.WHITE}
          svg={IconSvg.INFO}
          size={direction === ToastDirection.HORIZONTAL ? IconSize.MD : IconSize.LG}
          variant={IconVariant.CONTOUR}
        />
      </div>
      <div className={styles.contentBox}>
        <div className={styles.title}>{title}</div>
        <div className={styles.description}>{description}</div>
        {actionButton && (
          <span className={styles.actionButton}>
            <ButtonAlert variant={ButtonAlertVariant[variant]} {...actionButton} />
          </span>
        )}
      </div>

      <span className={styles.closeIconBox}>
        <Icon
          className={styles.closeIcon}
          svg={IconSvg.CLOSE}
          size={IconSize.LG}
          variant={IconVariant.CONTOUR}
          onClick={() => onClose(id)}
        />
      </span>
    </div>
  );

  return detached ? <Detached>{render()}</Detached> : render();
};
