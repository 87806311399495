import clsx from 'clsx';
import {Children, cloneElement, FC, useEffect, useMemo, useState} from 'react';
import {twMerge} from 'tailwind-merge';
import {Icon, IconColor, IconSvg} from '../../Icon/Icon';
import {TableRowProps} from '../TableRow';
import {TableCell} from './TableCell';
import {TableHeaderProps} from './TableHeader';
import {TestableElement} from '../../../external/types';

export type TableRowReflectionProps = TableRowProps & {
  header?: TableHeaderProps[];
  widths?: string[];
} & TestableElement;

export const TableRowReflection: FC<TableRowReflectionProps> = ({
  children = [],
  className = undefined,
  columnClassName = undefined,
  wrapperClassName = undefined,
  dropdownContent = undefined,
  dropdownAtColumn = undefined,
  dropdownOpen = false,
  onClick = undefined,
  caption = false,
  header = [],
  widths = [],
  testId = undefined,
}) => {
  const [showDropdownContent, setShowDropdownContent] = useState<boolean>(dropdownOpen);

  useEffect(() => {
    setShowDropdownContent(!!dropdownOpen);
  }, [dropdownOpen]);

  const columns = useMemo(
    () => Children.map(children, (child, index) => cloneElement(child, {width: widths[index]})),
    [children],
  );

  if (caption === false && header?.length !== undefined && header?.length !== columns.length) {
    throw new Error('Symfonia: Ilość kolumn musi odpowiadać ilości headerów');
  }

  const renderColumns = () => (
    <>
      <div
        onClick={() => setShowDropdownContent(!showDropdownContent)}
        className={twMerge(
          clsx(
            'flex w-full items-center h-[48px]',
            {
              'cursor-pointer': !!dropdownContent,
            },
            className,
          ),
        )}
      >
        {columns.map((column, index) => (
          <TableCell
            className={clsx(columnClassName, widths[index], header[index]?.className)}
            testId={`${testId ? `${testId}-` : ''}col-${index}`}
          >
            {dropdownAtColumn === index ? (
              <Icon
                color={IconColor.PRIMARY_500}
                svg={showDropdownContent ? IconSvg.KEYBOARD_ARROW_UP : IconSvg.KEYBOARD_ARROW_DOWN}
                withHover
              />
            ) : (
              column
            )}
          </TableCell>
        ))}
      </div>
      {dropdownContent && <div className={clsx({hidden: !showDropdownContent})}>{dropdownContent}</div>}
    </>
  );

  return (
    <div
      onClick={onClick}
      className={twMerge(
        clsx(
          {
            'flex flex-col': caption === false,
            'border-b-grey-100 border-b': caption === false && !className?.includes('border'),
          },
          wrapperClassName,
        ),
      )}
      data-testid="table-row"
    >
      {caption === false ? renderColumns() : children}
    </div>
  );
};
