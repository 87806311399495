import Error from '../../../assets/img/ic_plan_error.svg';
import Warning from '../../../assets/img/ic_plan_warning.svg';
import DOWN from '../../../assets/img/ic_server_connection_inactive.svg';
import OPERATIONAL from '../../../assets/img/ic_server_connection_active.svg';
import UNKNOWN from '../../../assets/img/ic_server_connection_unknown.svg';
import Upload from '../../../assets/img/ic_upload.svg';
import React, {FC, ReactElement, useMemo} from 'react';
import styles from './IconWithInfo.module.css';
import {ButtonPrimary, ButtonPrimarySize, IconSvg, Tooltip} from '@symfonia/brandbook';
import {TextFieldTooltip} from './../TextFieldTooltip/TextFieldTooltip';

function getIcon(status: IIconWithInfo['status']) {
  switch (status) {
    case KSeFOperatingStatus.Down:
      return DOWN;
    case KSeFOperatingStatus.Operational:
      return OPERATIONAL;
    case KSeFOperatingStatus.Unknown:
      return UNKNOWN;
    case PackageStatus.Expired:
      return Error;
    case PackageStatus.Expiring:
      return Warning;
    case OtherOperation.Upload:
      return Upload;
    default:
      return;
  }
}

export enum PackageStatus {
  Active = 'Active',
  Expiring = 'Expiring',
  Expired = 'Expired'
}

export enum KSeFOperatingStatus {
  Down = 'DOWN',
  Operational = 'OPERATIONAL',
  Unknown = 'UNKNOWN'
}

export enum OtherOperation {
  Upload = 'UPLOAD'
}

export interface IIconWithInfo {
  tooltipWidth?: string | undefined;
  status?: Partial<PackageStatus | KSeFOperatingStatus | OtherOperation>;
  tooltipContent: string | ReactElement;
  tooltipHeader?: string;
  icon?: IconSvg | undefined;
  btnClass?: string | undefined;
  iconClass?: string | undefined;
  action?: (event: React.MouseEvent<HTMLElement>) => void | undefined;
  id?: string | undefined;
  aria?: {
    ariaControls?: string | undefined,
    ariaHaspopup?: boolean | undefined,
    ariaExpanded?: boolean | undefined,
  };
}

export const IconWithInfo: FC<IIconWithInfo> = ({
                                                  tooltipWidth,
                                                  status,
                                                  tooltipContent,
                                                  tooltipHeader,
                                                  icon,
                                                  btnClass,
                                                  iconClass,
                                                  action,
                                                  id,
                                                  aria,
                                                }) => {
  const button = useMemo(() => {
    const iconStatus = getIcon(status);
    if (iconStatus) {
      return <TextFieldTooltip
        hoverMode={true}
        width={tooltipWidth}
        placement="bottom"
        zIndex={10}
        tooltipHeaderKey={tooltipHeader}
        tooltipContentKey={tooltipContent}>
        <img
          aria-label="package-usage-feedback-icon"
          src={iconStatus}
          className={iconClass ? iconClass : styles.icon}
          alt="icon"
        />
      </TextFieldTooltip>;
    }
    return <Tooltip text={tooltipContent}>
      <div>
        <ButtonPrimary size={ButtonPrimarySize.SM} lIcon={icon ?? IconSvg.ADD}/></div>
    </Tooltip>;
  }, [status, icon, iconClass, tooltipContent, tooltipHeader, tooltipWidth]);

  return (
    <div className={btnClass ? btnClass : styles.iconWrapper}
         aria-controls={aria?.ariaControls}
         aria-haspopup={aria?.ariaHaspopup}
         aria-expanded={aria?.ariaExpanded}
         data-testid={id}
         onClick={(e: React.MouseEvent<HTMLElement>) => action?.(e)}>
      {button}
    </div>
  );
};


