import {CURRENT_ENV, Environments} from "../../../env/common.env";

export const isLocalDev = document.location.hostname === 'localhost';
export const isTestRunner = CURRENT_ENV === Environments.test;
export const disableAppInsights = isLocalDev || document.location.hostname.startsWith('dev');
export const disableHotjar = isLocalDev || document.location.hostname.startsWith('dev');

export function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  if (value === null || value === undefined) return false;
  return true;
}

export function hasWhiteSpace(s: string) {
  return /\s/g.test(s);
}

export function isEmptyObj<Obj extends Record<PropertyKey, unknown>>(obj: Obj) {
  return Object.values(obj).some(v => v !== undefined);
}
