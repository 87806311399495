import {Popover} from '@mui/material';
import {FC, PropsWithChildren, RefObject, useEffect, useRef, useState} from 'react';

const DetachedChild: FC<PropsWithChildren<{parentRef: RefObject<HTMLElement>}>> = ({
  parentRef,
  children = undefined,
}) => {
  useEffect(() => {
    requestAnimationFrame(() => parentRef.current?.removeAttribute('tabindex'));
  }, []);

  return <>{children}</>;
};

export const Detached: FC<PropsWithChildren> = ({children = undefined}) => {
  const ref = useRef<HTMLDivElement>(null);
  const paperRef = useRef<HTMLDivElement>(null);
  const sizeRef = useRef<Record<string, string | number>>({});
  const [isRefBound, setRefBound] = useState<boolean>(false);

  useEffect(() => {
    requestAnimationFrame(() => {
      sizeRef.current = {width: `${ref.current!.offsetWidth}px`, height: `${ref.current!.offsetHeight}px`};
      setRefBound(true);
    });
  }, []);

  return (
    <div ref={ref} style={{...(isRefBound ? sizeRef.current : {})}}>
      {isRefBound || <div className="w-fit h-fit invisible">{children}</div>}
      {isRefBound && (
        <Popover
          open
          anchorEl={ref.current}
          anchorOrigin={{vertical: 'top', horizontal: 'right'}}
          transformOrigin={{vertical: 'top', horizontal: 'right'}}
          TransitionProps={{timeout: 0}}
          slotProps={{
            root: {
              hideBackdrop: true,
              style: {pointerEvents: 'none'},
              slotProps: {
                backdrop: {
                  open: false,
                },
              },
            },
            paper: {
              ref: paperRef,
              style: {
                width: 'auto',
                background: 'transparent',
                boxShadow: 'none',
                pointerEvents: 'all',
              },
            },
          }}
        >
          <DetachedChild parentRef={paperRef}>{children}</DetachedChild>
        </Popover>
      )}
    </div>
  );
};
