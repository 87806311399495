import {FC, ReactNode, useMemo} from 'react';
import {Grid} from '@mui/material';
import {dateParser} from '../../../helpers/dateParser';
import {LinearPackageLimit} from '../LinearPackageLimit/LinearPackageLimit';
import {Thresholds} from '../LinearPackageLimit/LinearPackageLimitType';

export type LinearPackageLimitProps = {
  usage: number,
  limit: number,
  lineLabel?: string;
  unit?: string,
  inactive?: boolean,
  label?: string,
  thresholds: Thresholds,
  loading?: boolean,
  enableInheritedBackground?: boolean,
  formatted?: { usage?: string | number, limit?: string | number }
}
export type PackageUsage = Omit<LinearPackageLimitProps, 'thresholds' | 'enableInheritedBackground'>

export type LinearPackageLimitsGroupProps = {
  loading?: boolean,
  dateTo: Date | undefined,
  packageUsage: PackageUsage[],
  dateLabel: string,
  enableInheritedBackground?: boolean,
  redirect?: () => void,
  textColor?: string | undefined,
  wrap?: boolean | undefined
  thresholds: [number, number, number];
}

export const LinearPackageLimitsGroup: FC<LinearPackageLimitsGroupProps> = ({
                                                                              dateTo,
                                                                              packageUsage,
                                                                              dateLabel,
                                                                              enableInheritedBackground,
                                                                              wrap,
                                                                              loading,
                                                                              thresholds,
                                                                            }) => {
  const renderedPackageLimitsItems = useMemo<ReactNode[]>(() => packageUsage.map((props, index) => (
    <Grid
      item xs={wrap ? 12 : 6}
      key={index}>
      <LinearPackageLimit
        {...props}
        label={props.label}
        usage={props.inactive ? 0 : props.usage}
        limit={props.limit}
        unit={props.unit}
        loading={loading}
        thresholds={thresholds}
        enableInheritedBackground={enableInheritedBackground}
      />
    </Grid>),
  ), [packageUsage, thresholds, enableInheritedBackground, wrap, loading]);

  return (
    <div className={'flex flex-col font-quicksand text-sm'}>
      <div className={'mb-[5px]'}>
        <span>{dateLabel}</span> <span>{dateTo && dateParser(dateTo)}</span>
      </div>
        {renderedPackageLimitsItems}
    </div>
  );
};
