import {lazy, Suspense, useEffect} from 'react';
import {useEnvState} from './env/env.zustand';
import {SpinnerWithCover} from '@symfonia/symfonia-ksef-components';

const Application = lazy(() => import('./Application'));

const Bootstrap = () => {
  const env = useEnvState(state => state.data);
  const queryEnv = useEnvState(state => state.queryEnv);

  useEffect(() => {
    queryEnv();
  }, []);

  if (env === null) {
    return <SpinnerWithCover/>;
  }

  return (
    <Suspense fallback={<SpinnerWithCover/>}>
      <Application/>
    </Suspense>
  );
};

export default Bootstrap;
