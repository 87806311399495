import React, {ReactNode} from 'react';

interface CircularChartConfig {
  percentageColor: string;
  availableLeftColor: string;
  outerLineColor: string;
  size: number;
  strokeWidth: number;
  outerLineStrokeWidth: number;
  clockwise: boolean;
}

interface CircularChartProps {
  config?: Partial<CircularChartConfig>;
  total: number;
  usedValue: number;
  insideText?: string | ReactNode;
}

const defaultConfig: CircularChartConfig = {
  size: 130,
  outerLineColor: '#8CD28C',
  percentageColor: '#009A00',
  availableLeftColor: '#D9F0D9',
  strokeWidth: 10,
  outerLineStrokeWidth: 5,
  clockwise: false,
};

export const CircularChart = ({config = defaultConfig, total, usedValue, insideText}: CircularChartProps) => {
  const mergedConfig = {...defaultConfig, ...config};

  const {
    size,
    outerLineColor,
    percentageColor,
    availableLeftColor,
    strokeWidth,
    outerLineStrokeWidth,
  } = mergedConfig;

  const radius = (size - 10) / 2;
  const percentCircumrefence = (radius - outerLineStrokeWidth) * 2 * Math.PI;
  const percent = Math.min(Math.max((usedValue / total) * 100, 0), 100);
  const offset = percentCircumrefence * (1 - percent / 100);

  return (
    <div className={`relative flex items-center justify-center w-[${size}px] h-[${size}px]`}>
      <svg width={size} height={size}>
        <circle
          stroke={outerLineColor}
          fill="transparent"
          strokeWidth={outerLineStrokeWidth}
          r={radius}
          cx={size / 2}
          cy={size / 2}
        />
        <circle
          stroke={availableLeftColor}
          fill="transparent"
          strokeWidth={strokeWidth}
          r={radius - outerLineStrokeWidth}
          cx={size / 2}
          cy={size / 2}
        />
        <circle
          stroke={percentageColor}
          fill="transparent"
          strokeWidth={strokeWidth}
          strokeDasharray={`${percentCircumrefence} ${percentCircumrefence}`}
          strokeDashoffset={config.clockwise ? offset : -offset}
          r={radius - outerLineStrokeWidth}
          cx={size / 2}
          cy={size / 2}
          transform={`rotate(-90 ${size / 2} ${size / 2})`}
          data-testid="chart-percentage"
        />
      </svg>
      <div className="absolute w-3/4 text-xs text-center">
        {insideText}
      </div>
    </div>
  );
};