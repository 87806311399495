import {create} from 'zustand';
import {immer} from 'zustand/middleware/immer';
import {persist} from 'zustand/middleware';
import {getCommonEnv} from './common.env';

export type EnvState = {
  data: ReturnType<typeof getCommonEnv> | null;
  queryEnv: () => void;
  version: string;
  queryVersion: () => void;
};

export const useEnvState = create<EnvState>()(
  persist(
    immer(set => ({
      data: null,
      queryEnv: () => {
        fetch('.env.json')
          .then(response => {
            if (response.ok) {
              response
                .json()
                .then(data => {
                  const dataAsObj = JSON.parse(data as string) as Record<string, string>;
                  set(draft => {
                    draft.data = getCommonEnv(dataAsObj);
                  });
                })
                .catch(console.error);
            } else {
              throw response;
            }
          })
          .catch(console.error);
      },
      version: '',
      queryVersion: () => {
        fetch('.version')
          .then(response => {
            if (response.ok) {
              response
                .text()
                .then(data => {
                  set(draft => {
                    draft.version = data.split('=')[1];
                  });
                })
                .catch(console.error);
            } else {
              throw response;
            }
          })
          .catch(console.error);
      },
    })),
    {name: 'EnvState'},
  ),
);
