import React, {FC, ReactElement} from 'react';
import styles from './IconWithTooltip.module.css';
import {Icon, IconColor, IconSize, IconSvg, IconVariant, Tooltip} from '@symfonia/brandbook';
import {TooltipProps as MuiTooltipProps} from '@mui/material/Tooltip/Tooltip';

type IconType = {
  icon: IconSvg;
  color: IconColor;
}

export interface IconWithTooltipProps {
  tooltipWidth?: string | undefined;
  tooltipContent: string | ReactElement;
  tooltipHeader?: string;
  icon?: IconType | undefined;
  btnClass?: string | undefined;
  iconClass?: string | undefined;
  tooltipClass?: string | undefined;
  action?: (event: React.MouseEvent<HTMLElement>) => void | undefined;
  id?: string | undefined;
  size?: IconSize;
  aria?: {
    ariaControls?: string | undefined,
    ariaHaspopup?: boolean | undefined,
    ariaExpanded?: boolean | undefined,
  };
  onClick?: () => void;
  tooltipPosition?: MuiTooltipProps['placement'];
}

export const IconWithTooltip: FC<IconWithTooltipProps> = ({
                                                            tooltipContent,
                                                            tooltipHeader,
                                                            icon,
                                                            size = IconSize.LG,
                                                            btnClass,
                                                            action,
                                                            aria,
                                                            onClick,
                                                            tooltipClass,
                                                            tooltipPosition
                                                          }) => {

  return (
    <div className={btnClass ? btnClass : styles.iconWrapper}
         aria-controls={aria?.ariaControls}
         aria-haspopup={aria?.ariaHaspopup}
         aria-expanded={aria?.ariaExpanded}
         onClick={(e: React.MouseEvent<HTMLElement>) => action?.(e)}>
      <Tooltip text={tooltipContent} tooltipClass={tooltipClass} position={tooltipPosition}>
        <Icon svg={icon?.icon ?? IconSvg.ADD_BOX} size={size} color={icon?.color ?? IconColor.GREEN_600}
              variant={IconVariant.CONTOUR} onClick={onClick}/>
      </Tooltip>
    </div>
  );
};


