import React, {FC, ReactElement} from 'react';
import {Badge, BadgeSize, IconColor, IconSvg} from '@symfonia/brandbook';
import {IconWithTooltip} from '../IconWithTooltip/IconWithTooltip';


interface IEventsBell {
  iconSrc?: string,
  redirect: () => void,
  badge?: number | undefined,
  tooltipWidth?: string | undefined,
  tooltipHeader?: string | undefined,
  tooltipContent: string | ReactElement,
  isMobile?: boolean,
}

export const EventsBell: FC<IEventsBell> = ({
                                              badge,
                                              redirect,
                                              tooltipWidth,
                                              tooltipHeader,
                                              tooltipContent,
                                              isMobile,
                                            }) => {
  return <button onClick={redirect}
                 className={'w-6 h-6 z-10 cursor-pointer ' + (!isMobile ? 'mr-6' : 'm-[10px]' + ' relative flex items-start')}>
    <div className={'relative w-auto h-auto m-0'}>
      <IconWithTooltip
        tooltipWidth="auto"
        icon={{icon: IconSvg.NOTIFICATIONS, color: IconColor.GREY_500}}
        tooltipHeader={tooltipHeader}
        tooltipContent={tooltipContent}
        btnClass={'m-0'}
      />
      <Badge
        className={'ml-[6px] text-[10px] h-[10px] w-[10px] absolute z-10 -right-[5px] top-0'}
        text={badge?.toString() ?? ''}
        size={BadgeSize.SM}
      />
    </div>
  </button>;
};
