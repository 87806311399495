export const emptyArray = Object.freeze([]);
export const getEmptyArray = <T extends unknown[]>() => emptyArray as unknown as T;

export const colorNames = [
  'akwamaryna',
  'alabastrowy',
  'amarantowy',
  'ametystowy',
  'antracytowy',
  'atramentowy',
  'bahama yellow',
  'bananowy',
  'beżowy',
  'biały',
  'biskupi',
  'błękit królewski',
  'błękit paryski',
  'błękit pruski',
  'błękit Thénarda',
  'błękit Turnbulla',
  'bordowy, bordo',
  'brązowy',
  'brunatny',
  'brzoskwiniowy',
  'buraczkowy',
  'burgund',
  'bursztynowy',
  'bury',
  'ceglasty',
  'chabrowy',
  'chamois',
  'cielisty',
  'cukierkowy róż',
  'cyjan',
  'cyjan (druk)',
  'cyklamen',
  'cynamonowy',
  'cynobrowy, cynober',
  'cytrynowy',
  'czarny',
  'czekoladowy',
  'czerwień wzrokowa',
  'czerwień żelazowa',
  'czerwony',
  'écru',
  'eozyna',
  'feldgrau',
  'fioletowy',
  'fiołkowy',
  'fuksja',
  'gołębi',
  'grafitowy',
  'granatowy',
  'groszkowy',
  'grynszpan',
  'heban, hebanowy',
  'herbaciany',
  'indygo',
  'jagodowy, borówkowy',
  'jasnożółty',
  'jaśminowy',
  'kakaowy',
  'kanarkowy',
  'kardynalski',
  'karmazynowy',
  'karminowy',
  'kasztanowy',
  'kawowy',
  'khaki',
  'kobaltowy',
  'koniakowy',
  'koralowy',
  'kość słoniowa',
  'kremowy',
  'krwisty',
  'lawendowy',
  'lapis-lazuli',
  'lazurowy',
  'lila',
  'lila róż',
  'liliowy',
  'limonkowy',
  'łososiowy',
  'magenta (HTML)',
  'magenta (druk)',
  'mahoń, mahoniowy',
  'majtkowy',
  'makowy',
  'malachitowy',
  'malinowy',
  'marchewkowy',
  'marengo',
  'miedziany',
  'miętowy',
  'minia',
  'miodowy',
  'mleczny',
  'modry',
  'morelowy',
  'morski',
  'mosiądz/-owy',
  'mysi',
  'niebieski',
  'niebieskofioletowy',
  'oberżynowy',
  'ochra',
  'oliwkowy',
  'oranż',
  'orzechowy',
  'palisander',
  'patynowy',
  'pąsowy',
  'perłowy',
  'piaskowy',
  'pistacjowy',
  'platynowy',
  'płowy',
  'pomarańczowy',
  'popielaty',
  'porcelanowy',
  'poziomkowy',
  'purpurowy',
  'rdzawy',
  'róż indyjski',
  'róż pompejański',
  'róż wenecki',
  'różowy',
  'rubinowy',
  'rudy',
  'sadza angielska',
  'seledynowy',
  'sepia',
  'siarkowy',
  'siny',
  'siwy',
  'sjena palona',
  'słomkowy',
  'smolisty',
  'spiżowy',
  'srebrny',
  'stalowy',
  'stare złoto',
  'szafirowy',
  'szafranowy',
  'szary',
  'szkarłatny żołądź',
  'szmaragdowy',
  'śliwkowy',
  'świniowy',
  'tabaczkowy',
  'tango',
  'truskawkowy',
  'turkusowy',
  'tycjan',
  'ugier',
  'ultramaryna',
  'umbra',
  'winny',
  'wiśniowy',
  'woskowy',
  'wrzosowy',
  'zieleń butelkowa',
  'zieleń jaskrawa',
  'zieleń Veronese’a',
  'zieleń wiosenna',
  'zieleń zgniła',
  'zielony',
  'złocisty',
  'złoty',
  'żółty',
  'żółty (druk)',
  'kość słoniowa / mleczny',
  'arbuzowy',
  'majerankowy',
  'czerwień alizarynowa',
  'szkarłatny',
  'piwny',
  'morska zieleń',
  'trawiasty',
  'jagodowy',
];

export enum zIndex {
  belowModal = 1250,
  modal = 1300,
  aboveModal = 1350,
  tooltip = 1400,
}
