import {FC} from 'react';
import clsx from 'clsx';
import {Thumbnail} from '../Thumbnail/Thumbnail';
import {ButtonPrimary, ButtonPrimaryProps, ButtonPrimaryWidth} from '../Button/ButtonPrimary';
import {ButtonTertiary, ButtonTertiaryProps, ButtonTertiaryWidth} from '../Button/ButtonTertiary';
import {TestableElement} from '../../external/types';

export enum AspectRatio {
  SQUARE = 'SQUARE (1:1)',
  NORMAL = 'NORMAL (4:3)',
  WIDE = 'WIDE (16:9)',
}

export enum TileSize {
  SM = 'SM',
  MD = 'MD',
  LG = 'LG',
}

export type TileProps = {
  imgUrl?: string;
  aspectRatio?: AspectRatio;
  size?: TileSize;
  withBackground?: boolean;
  imageDescription?: string;
  title: string;
  description: string;
  primaryButton?: Pick<ButtonPrimaryProps, 'text' | 'onClick'>;
  secondaryButton?: Pick<ButtonTertiaryProps, 'text' | 'onClick'>;
} & TestableElement;

export const Tile: FC<TileProps> = ({
  imgUrl = '',
  aspectRatio = AspectRatio.NORMAL,
  size = TileSize.MD,
  withBackground = true,
  imageDescription = '',
  title,
  description,
  primaryButton = undefined,
  secondaryButton = undefined,
  testId = undefined,
}) => {
  const styles = {
    component: clsx('flex flex-col font-quicksand break-all', {
      'p-[32px] drop-shadow-lg bg-white rounded-lg': withBackground,
      'gap-y-4': size === TileSize.LG || size === TileSize.MD,
      'w-[428px]': size === TileSize.LG,
      'w-[328px]': size === TileSize.MD,
      'w-[212px] gap-y-[16px]': size === TileSize.SM,
    }),
    img: clsx({
      'aspect-[1/1]': aspectRatio === AspectRatio.SQUARE,
      'aspect-[4/3]': aspectRatio === AspectRatio.NORMAL,
      'aspect-[16/9]': aspectRatio === AspectRatio.WIDE,
    }),
    contentBox: clsx('flex flex-col gap-y-[16px]', {'gap-y-[8px]': size === TileSize.SM}),
    imageDescription: clsx('text-grey-700 font-medium text-sm leading-[16px]', {
      'text-xs': size === TileSize.SM,
    }),
    title: clsx('font-bold', {
      'text-2xl': size === TileSize.LG || size === TileSize.MD,
      'text-xl': size === TileSize.SM,
    }),
    description: clsx('font-roboto', {
      'text-xs': size === TileSize.SM,
    }),
    buttonBox: clsx('flex items-center justify-end', {'flex-col-reverse': size === TileSize.SM}),
    primaryButton: clsx({'ml-[24px]': size === TileSize.LG, 'ml-[16px]': size === TileSize.MD}),
    secondaryButton: clsx({'mt-[16px]': size === TileSize.SM}),
  };

  return (
    <div className={styles.component} data-test-element="tile" data-testid={testId}>
      {imgUrl && <Thumbnail className={styles.img} imgUrl={imgUrl} />}
      {(imageDescription || title || description) && (
        <div className={styles.contentBox}>
          {imageDescription && <div className={styles.imageDescription}>{imageDescription}</div>}
          {title && <div className={styles.title}>{title}</div>}
          {description && <div className={styles.description}>{description}</div>}
        </div>
      )}
      {(primaryButton?.text || secondaryButton?.text) && (
        <div className={styles.buttonBox}>
          {secondaryButton?.text && (
            <ButtonTertiary
              className={styles.secondaryButton}
              width={size === TileSize.SM ? ButtonTertiaryWidth.FULL : ButtonTertiaryWidth.BASE}
              text={secondaryButton?.text}
              onClick={secondaryButton?.onClick}
            />
          )}
          {primaryButton?.text && (
            <ButtonPrimary
              className={styles.primaryButton}
              width={size === TileSize.SM ? ButtonPrimaryWidth.FULL : ButtonPrimaryWidth.BASE}
              text={primaryButton?.text}
              onClick={primaryButton?.onClick}
            />
          )}
        </div>
      )}
    </div>
  );
};
