import {Children, cloneElement, ReactElement, useEffect, useRef} from 'react';
import {Subject} from '../../helpers/Subject';

export type ScrollOptions = {
  behavior?: ScrollBehavior;
  block?: 'start' | 'center' | 'end' | 'nearest';
  inline?: 'start' | 'center' | 'end' | 'nearest';
};

export type ScrollIntoViewProps = {
  children: ReactElement;
  id?: any;
  scrollSubject: Subject<any>;
  options?: ScrollOptions;
};

export const ScrollIntoView = <ELEMENT extends HTMLElement>({
  children,
  id = undefined,
  scrollSubject,
  options = {
    behavior: 'auto',
    inline: 'nearest',
    block: 'nearest',
  },
}: ScrollIntoViewProps) => {
  const ref = useRef<ELEMENT>(null);

  useEffect(() => {
    return scrollSubject.subscribe(ofId => {
      if (id === ofId) {
        ref.current!.scrollIntoView(options);
      }
    });
  }, [ref.current]);

  return <>{cloneElement(Children.only(children), {ref})}</>;
};
