import clsx from 'clsx';
import {FC} from 'react';
import {NavLink} from 'react-router-dom';
import '../../../tailwind.utilities.css';
import {TestableElement} from '../../external/types';
import {Icon, IconSize, IconSvg, IconVariant} from '../Icon/Icon';

export enum ButtonAlertWidth {
  FULL = 'FULL',
  FIT = 'FIT',
  BASE = 'BASE',
  INITIAL = 'INITIAL',
}

export enum ButtonAlertVariant {
  INFO = 'INFO',
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
}

export type ButtonAlertProps = {
  lIcon?: IconSvg | null;
  rIcon?: IconSvg | null;
  text?: string;
  onClick?: () => void;
  href?: string | undefined;
  className?: string;
  disabled?: boolean;
  width?: ButtonAlertWidth;
  variant?: ButtonAlertVariant;
  stickyIcons?: boolean;
} & TestableElement;

type ButtonStyles = {
  button: string;
  icon: string;
  text: string;
};

const getStyles = (config: {
  variant: ButtonAlertVariant;
  width: ButtonAlertWidth;
  disabled: boolean;
  hasText: boolean;
  stickyIcons: boolean;
}): ButtonStyles => {
  const styles = {
    button: clsx(
      'group whitespace-nowrap inline-flex justify-center items-center rounded select-none focus-visible:outline focus-visible:outline-[1px] relative p-[4px] active:bg-transparent',
      {
        'w-full': config.hasText && config.width === ButtonAlertWidth.FULL,
        'min-w-[200px]': config.hasText && config.width === ButtonAlertWidth.BASE,
        'w-fit': config.hasText && config.width === ButtonAlertWidth.FIT,
        'h-[20px] min-w-[20px]': !config.hasText,
        'h-[24px] min-w-[24px]': config.hasText,
        'pointer-events-none': config.disabled,
        'focus-visible:outline-primary-600': !config.disabled && config.variant === ButtonAlertVariant.SUCCESS,
        'focus-visible:outline-blue1-600': !config.disabled && config.variant === ButtonAlertVariant.INFO,
        'focus-visible:outline-red-600': !config.disabled && config.variant === ButtonAlertVariant.ERROR,
        'focus-visible:outline-orange-600': !config.disabled && config.variant === ButtonAlertVariant.WARNING,
      },
    ),
    icon: clsx({
      'filter-grey-300': config.disabled,
      'filter-green-500 group-hover:filter-green-400 group-focus:filter-green-500 group-active:filter-green-600':
        !config.disabled && config.variant === ButtonAlertVariant.SUCCESS,
      'filter-blue1-500 group-hover:filter-blue1-400 group-focus:filter-blue1-500 group-active:filter-blue1-600':
        !config.disabled && config.variant === ButtonAlertVariant.INFO,
      'filter-red-500 group-hover:filter-red-400 group-focus:filter-red-500 group-active:filter-red-600':
        !config.disabled && config.variant === ButtonAlertVariant.ERROR,
      'filter-orange-500 group-hover:filter-orange-400 group-focus:filter-orange-500 group-active:filter-orange-600':
        !config.disabled && config.variant === ButtonAlertVariant.WARNING,
    }),
    text: clsx('font-quicksand font-bold text-sm', {
      grow: !!config.stickyIcons,
      'text-grey-300': config.disabled,
      'text-green-500 group-hover:text-green-400 group-focus:text-green-500 group-active:text-green-600':
        !config.disabled && config.variant === ButtonAlertVariant.SUCCESS,
      'text-blue1-500 group-hover:text-blue1-400 group-focus:text-blue1-500 group-active:text-blue1-600':
        !config.disabled && config.variant === ButtonAlertVariant.INFO,
      'text-red-500 group-hover:text-red-400 group-focus:text-red-500 group-active:text-red-600':
        !config.disabled && config.variant === ButtonAlertVariant.ERROR,
      'text-orange-500 group-hover:text-orange-400 group-focus:text-orange-500 group-active:text-orange-600':
        !config.disabled && config.variant === ButtonAlertVariant.WARNING,
    }),
  };

  return styles;
};

export const ButtonAlert: FC<ButtonAlertProps> = ({
  lIcon = null,
  rIcon = null,
  text = '',
  href = undefined,
  className = '',
  disabled = false,
  variant = ButtonAlertVariant.INFO,
  width = ButtonAlertWidth.FIT,
  onClick = undefined,
  stickyIcons = false,
  testId = undefined,
}) => {
  const styles = getStyles({variant, width, disabled, hasText: text.length > 0, stickyIcons});

  const renderContent = () => (
    <>
      {lIcon && (
        <Icon
          className={clsx({[styles.icon]: true, 'mr-[4px]': text.length > 0})}
          svg={lIcon}
          size={IconSize.MD}
          variant={IconVariant.CONTOUR}
        />
      )}
      {text && <span className={styles.text}>{text}</span>}
      {rIcon && (
        <Icon
          className={clsx({[styles.icon]: true, 'ml-[4px]': text.length > 0})}
          svg={rIcon}
          size={IconSize.MD}
          variant={IconVariant.CONTOUR}
        />
      )}
    </>
  );

  const componentProps = {
    onClick,
    className: clsx([styles.button, className]),
    tabIndex: 0,
    disabled,
    'data-test-element': 'button-alert',
    'data-testid': testId,
  };

  return (
    <>
      {href && (
        <NavLink {...componentProps} to={href}>
          {renderContent()}
        </NavLink>
      )}
      {!href && (
        <button {...componentProps} type="button">
          {renderContent()}
        </button>
      )}
    </>
  );
};
