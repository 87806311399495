import {defineMessages} from 'react-intl';

type ArrayElement<ArrayType> = ArrayType extends readonly (infer ElementType)[] ? ElementType : never;

export const calendarT = defineMessages({
  dzisiaj: {
    defaultMessage: 'Dzisiaj',
    id: 'ae13fb58-2e48-4224-83e5-94de9d6f79c4',
    description: 'Przycisk zaznaczania dzisiejszej daty, wartość dla "Dzisiaj"',
  },
  styczen: {
    defaultMessage: 'Styczeń',
    id: '83a3ba8b-02f2-4a80-be54-31621d1b4675',
    description: 'Miesiąc w kalendarzu - wartość dla "Styczeń"',
  },
  luty: {
    defaultMessage: 'Luty',
    id: '7cd83387-63b8-40df-bb79-bf216997b02c',
    description: 'Miesiąc w kalendarzu - wartość dla "Luty"',
  },
  marzec: {
    defaultMessage: 'Marzec',
    id: 'ae24cdc8-bcd0-4395-a825-9b1312f523db',
    description: 'Miesiąc w kalendarzu - wartość dla "Marzec"',
  },
  kwiecien: {
    defaultMessage: 'Kwiecień',
    id: '216ecf1c-1ba1-4aca-9bc7-813dd1487f6b',
    description: 'Miesiąc w kalendarzu - wartość dla "Kwiecień"',
  },
  maj: {
    defaultMessage: 'Maj',
    id: '7252cfdd-dfd4-42aa-8818-1c318fc59a24',
    description: 'Miesiąc w kalendarzu - wartość dla "Maj"',
  },
  czerwiec: {
    defaultMessage: 'Czerwiec',
    id: '94d6c02b-a36f-4bfa-842f-1a76d7c20291',
    description: 'Miesiąc w kalendarzu - wartość dla "Czerwiec"',
  },
  lipiec: {
    defaultMessage: 'Lipiec',
    id: 'bb8030da-193d-4357-a05c-34504d5ddb2b',
    description: 'Miesiąc w kalendarzu - wartość dla "Lipiec"',
  },
  sierpien: {
    defaultMessage: 'Sierpień',
    id: '8e514cf1-3237-4f45-8095-e879f85cb526',
    description: 'Miesiąc w kalendarzu - wartość dla "Sierpień"',
  },
  wrzesien: {
    defaultMessage: 'Wrzesień',
    id: 'ada34467-bd91-43e8-a3fa-c27403fdc5f3',
    description: 'Miesiąc w kalendarzu - wartość dla "Wrzesień"',
  },
  pazdziernik: {
    defaultMessage: 'Październik',
    id: '8065467a-7d69-4adf-a196-ec9108036f30',
    description: 'Miesiąc w kalendarzu - wartość dla "Październik"',
  },
  listopad: {
    defaultMessage: 'Listopad',
    id: '386e9e63-cb4c-48f1-9e7c-676dcf9472d0',
    description: 'Miesiąc w kalendarzu - wartość dla "Listopad"',
  },
  grudzien: {
    defaultMessage: 'Grudzień',
    id: '1dddb1d1-b907-4660-a270-6a073fca61f1',
    description: 'Miesiąc w kalendarzu - wartość dla "Grudzień"',
  },

  poniedzialek: {
    defaultMessage: 'Poniedziałek',
    id: '8a7a5bd7-8e97-425e-aac3-9d7615875728',
    description: 'Dni w kalendarzu - wartość dla "Poniedziałek"',
  },
  wtorek: {
    defaultMessage: 'Wtorek',
    id: '4e394a03-13bc-4fc6-a274-cf420601770b',
    description: 'Dni w kalendarzu - wartość dla "Wtorek"',
  },
  sroda: {
    defaultMessage: 'Środa',
    id: '8771d6e8-9228-43ba-aaeb-619fa681f26c',
    description: 'Dni w kalendarzu - wartość dla "Środa"',
  },
  czwartek: {
    defaultMessage: 'Czwartek',
    id: '8161db2b-979b-4000-8b79-42078bd765b1',
    description: 'Dni w kalendarzu - wartość dla "Czwartek"',
  },
  piatek: {
    defaultMessage: 'Piątek',
    id: 'e0cdfa1d-c92a-4748-8aed-6fe3d7d54913',
    description: 'Dni w kalendarzu - wartość dla "Piątek"',
  },
  sobota: {
    defaultMessage: 'Sobota',
    id: '77ce37bf-91a5-4c0a-aa32-20ab3aedd526',
    description: 'Dni w kalendarzu - wartość dla "Sobota"',
  },
  niedziela: {
    defaultMessage: 'Niedziela',
    id: '7194c183-59d0-4dba-9033-1900ad07b87b',
    description: 'Dni w kalendarzu - wartość dla "Niedziela"',
  },
});

export const tMonths = [
  calendarT.styczen,
  calendarT.luty,
  calendarT.marzec,
  calendarT.kwiecien,
  calendarT.maj,
  calendarT.czerwiec,
  calendarT.lipiec,
  calendarT.sierpien,
  calendarT.wrzesien,
  calendarT.pazdziernik,
  calendarT.listopad,
  calendarT.grudzien,
] as const;

export const tDays = [
  calendarT.poniedzialek,
  calendarT.wtorek,
  calendarT.sroda,
  calendarT.czwartek,
  calendarT.piatek,
  calendarT.sobota,
  calendarT.niedziela,
] as const;

export enum CalendarView {
  Calendar,
  MonthSelector,
  YearSelector,
}

export const getMonthIndex = (month: ArrayElement<typeof tMonths>): number => tMonths.findIndex(m => m === month);

export const monthMatrix = [
  [calendarT.styczen, calendarT.luty, calendarT.marzec],
  [calendarT.kwiecien, calendarT.maj, calendarT.czerwiec],
  [calendarT.lipiec, calendarT.sierpien, calendarT.wrzesien],
  [calendarT.pazdziernik, calendarT.listopad, calendarT.grudzien],
];

export const moduloDivideArray = <T>(array: T[], modulo: number): T[][] => {
  return array.reduce((a, c, i) => {
    if (i % modulo === 0) {
      a.push([]);
    }
    a[a.length - 1].push(c);
    return a;
  }, [] as T[][]);
};

export const getDecade = (year: number): number => Math.floor(year / 10) * 10;
export const getDecadeMatrix = (year: number) => {
  const decade: number = getDecade(year);
  const base = Array.from({length: 10}, (v, i) => decade + i);
  base.unshift(decade - 1);
  base.push(decade + 10);
  return moduloDivideArray(base, 3);
};

export const dayOfYear = (date: Date): number =>
  Math.floor((date.getTime() - new Date(date.getFullYear(), 0, 0).getTime()) / 1000 / 60 / 60 / 24);

export enum EditedDateType {
  DATE,
  END_DATE,
}

export const getDaysInMonth = (date: Date): number => new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
export const getFirstDayOfMonthDate = (date: Date): Date => new Date(date.getFullYear(), date.getMonth(), 1);
export const getLastDayOfMonthDate = (date: Date): Date => new Date(date.getFullYear(), date.getMonth() + 1, 0);
export const getFirstDayOfMonth = (year: number, monthIndex: number): Date => new Date(year, monthIndex, 1);
export const getLastDayOfMonth = (year: number, monthIndex: number): Date => new Date(year, monthIndex + 1, 0);

export const getFirstDayOfMonthOffset = (date: Date): number => getFirstDayOfMonthDate(date).getDay();
export const getWeeksInMonth = (date: Date) => Math.ceil((getDaysInMonth(date) + getFirstDayOfMonthOffset(date)) / 7);
export const getOffsetDate = (date: Date, offsetDays: number): Date => {
  return new Date(new Date(date).setDate(date.getDate() + offsetDays));
};
export const getOffsetMonth = (date: Date, offsetMonth: number): Date => {
  return new Date(new Date(date).setMonth(date.getMonth() + offsetMonth));
};
export const getOffsetYear = (date: Date, offsetYear: number): Date => {
  return new Date(new Date(date).setFullYear(date.getFullYear() + offsetYear));
};
export const getFirstDayOfTheYear = (date: Date): Date => {
  const nextDate = new Date(date);
  nextDate.setDate(1);
  nextDate.setMonth(0);
  return nextDate;
};
export const getLastDayOfTheYear = (date: Date): Date => {
  const nextDate = new Date(date);
  nextDate.setDate(31);
  nextDate.setMonth(11);
  return nextDate;
};
export const getMonthMatrix = (date: Date, weeksToDisplay?: number): Date[][] => {
  const firstDayOfMonthDate = getFirstDayOfMonthDate(date);

  return Array.from({length: weeksToDisplay || getWeeksInMonth(date)}, (wv, wi) =>
    Array.from({length: 7}, (dv, di) => getOffsetDate(firstDayOfMonthDate, wi * 7 + di - firstDayOfMonthDate.getDay())),
  );
};

export const isSameDay = (dateA: Date, dateB: Date): boolean =>
  dateA.getFullYear() === dateB.getFullYear() &&
  dateA.getMonth() === dateB.getMonth() &&
  dateA.getDate() === dateB.getDate();

export const isSameMonth = (dateA: Date, dateB: Date): boolean =>
  dateA.getFullYear() === dateB.getFullYear() && dateA.getMonth() === dateB.getMonth();

export type DateObj = {
  year: number;
  month: number;
  day: number;
};

export const isBetweenDates = (date: Date, startDate: Date, endDate: Date): boolean =>
  date.getTime() > startDate.getTime() && date.getTime() < endDate.getTime();

export const isWithinDates = (date: Date, startDate: Date, endDate: Date): boolean =>
  date.getTime() >= startDate.getTime() && date.getTime() <= endDate.getTime();

export const isBeforeDate = (date: Date, refDate: Date): boolean => date.getTime() < refDate.getTime();
export const isBeforeEqDate = (date: Date, refDate: Date): boolean => date.getTime() <= refDate.getTime();

export const isAfterDate = (date: Date, refDate: Date): boolean => date.getTime() > refDate.getTime();
export const isAfterEqDate = (date: Date, refDate: Date): boolean => date.getTime() >= refDate.getTime();

export const isFormerMonth = (date: Date, refDate: Date): boolean => {
  const formerMonthDate = getOffsetMonth(refDate, -1);
  return isSameMonth(date, formerMonthDate);
};

export const isLatterMonth = (date: Date, refDate: Date): boolean => {
  const latterMonthDate = getOffsetMonth(refDate, +1);
  return isSameMonth(date, latterMonthDate);
};

export const dateObjToDate = (d: Date | DateObj) => (d instanceof Date ? d : new Date(d.year, d.month - 1, d.day));

export const dateToDateObj = (d: Date): DateObj => {
  return {
    day: d.getDate(),
    month: d.getMonth() + 1,
    year: d.getFullYear(),
  };
};

export const isFirstDayOfTheMonth = (date: Date): boolean => {
  const firstDay = getFirstDayOfMonthDate(date);
  return isSameDay(firstDay, date);
};

export const mondayAsAFirstDayOfAWeek = (_monthMatrix: Date[][]): Date[][] => {
  const beginsWithFirstDay = isFirstDayOfTheMonth(_monthMatrix[0][0]);

  const output = _monthMatrix.map((week, weekIndex) =>
    week.reduce<Date[]>((fixedWeek, day, dayIndex) => {
      if (dayIndex !== 0) {
        fixedWeek.push(day);
      }

      if (dayIndex === 6) {
        fixedWeek.push(getOffsetDate(day, 1));
      }

      return fixedWeek;
    }, []),
  );

  if (beginsWithFirstDay) {
    output.pop();
    output.unshift([
      getOffsetDate(_monthMatrix[0][0], -6),
      getOffsetDate(_monthMatrix[0][0], -5),
      getOffsetDate(_monthMatrix[0][0], -4),
      getOffsetDate(_monthMatrix[0][0], -3),
      getOffsetDate(_monthMatrix[0][0], -2),
      getOffsetDate(_monthMatrix[0][0], -1),
      _monthMatrix[0][0],
    ]);
  }

  return output;
};

export const dateToString = (d: Date): string => {
  return [d.getFullYear(), `0${d.getMonth() + 1}`.slice(-2), `0${d.getDate()}`.slice(-2)].join('-');
};

export const dateToYearMonthString = (d: Date): string => {
  return [d.getFullYear(), `0${d.getMonth() + 1}`.slice(-2)].join('-');
};

export const dateToYearString = (d: Date) => {
  return d.getFullYear().toString();
};

