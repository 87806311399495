import clsx from 'clsx';
import {FC, FocusEvent, ReactElement, useMemo, useState} from 'react';
import {TestableElement} from '../../external/types';
import {ButtonSecondary, ButtonSecondarySize} from '../Button/ButtonSecondary';
import {ButtonTertiary, ButtonTertiarySize} from '../Button/ButtonTertiary';
import {IconSvg} from '../Icon/Icon';
import {Input, InputSize, InputType, InputWidth} from '../Input/Input';

export enum PaginationVariant {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
}

export type PaginationProps = {
  pageCount: number;
  currentPage: number;
  onChange: (nextPage: number) => void;
  variant?: PaginationVariant;
  maxPages?: number;
} & TestableElement;

enum PaginationInputPosition {
  LEFT,
  RIGHT,
}

export const Pagination: FC<PaginationProps> = ({
  pageCount = 1,
  currentPage = 1,
  onChange,
  variant = PaginationVariant.PRIMARY,
  maxPages = 7,
  testId = undefined,
}) => {
  const styles = {
    component: 'inline-flex h-[40px] items-center select-none gap-4',
    pageNumber:
      'w-[24px] h-[24px] select-none font-quicksand font-bold inline-flex justify-center items-center rounded-[4px] hover:text-primary-600',
    clickablePage: 'cursor-pointer text-primary-500',
    disabledPage: 'cursor-default text-grey-500',
    currentPage: 'cursor-default bg-primary-100 text-primary-500',
  };
  const [showInput, setShowInput] = useState<boolean>(false);
  const [inputPosition, setInputPosition] = useState<PaginationInputPosition | null>(null);

  const pages = useMemo(
    () =>
      Array<number>(pageCount)
        .fill(0)
        .map((_, index) => index + 1),
    [pageCount],
  );

  const isCurrentPage = (n: number): boolean => {
    return n === currentPage;
  };

  const renderArrowLeft = () => {
    const buttonParams = {
      lIcon: IconSvg.CHEVRON_LEFT,
      className: 'mr-[16px] flex',
      disabled: currentPage === 1,
      onClick: () => onChange(currentPage - 1),
    };
    return variant === PaginationVariant.PRIMARY ? (
      <ButtonTertiary size={ButtonTertiarySize.SM} {...buttonParams} />
    ) : (
      <ButtonSecondary size={ButtonSecondarySize.SM} {...buttonParams} />
    );
  };

  const renderArrowRight = () => {
    const buttonParams = {
      lIcon: IconSvg.CHEVRON_RIGHT,
      className: 'ml-[16px] flex',
      disabled: currentPage === pageCount,
      onClick: () => onChange(currentPage + 1),
    };
    return variant === PaginationVariant.PRIMARY ? (
      <ButtonTertiary size={ButtonTertiarySize.SM} {...buttonParams} />
    ) : (
      <ButtonSecondary size={ButtonSecondarySize.SM} {...buttonParams} />
    );
  };

  const renderDotz = (forPosition: PaginationInputPosition): ReactElement => {
    const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
      if (event.target.value) {
        const nextPage = parseInt(event.target.value, 10);
        if (!isCurrentPage(nextPage)) {
          onChange(nextPage);
        }
      }
      setShowInput(false);
      setInputPosition(null);
    };

    return (
      <span className="inline-flex w-[40px] justify-center">
        {showInput && inputPosition === forPosition ? (
          <Input
            autofocus
            width={InputWidth.FULL}
            alignTextCenter
            size={InputSize.SM}
            type={InputType.NUMBER}
            min={0}
            max={pageCount}
            noOutline
            onInput={value => {
              const nextValue = parseInt(value, 10);
              return `${Math.min(pageCount, Math.max(0, nextValue))}`;
            }}
            onBlur={handleBlur}
            onKeyDown={event => {
              if (event.key.toLowerCase() === 'enter') {
                handleBlur(event as unknown as FocusEvent<HTMLInputElement>);
              }
            }}
          />
        ) : (
          <span
            className="cursor-pointer"
            onClick={() => {
              setShowInput(true);
              setInputPosition(forPosition);
            }}
          >
            ...
          </span>
        )}
      </span>
    );
  };

  const renderPageNo = (n: number): ReactElement => {
    if (
      pageCount <= maxPages ||
      n === 1 ||
      n === pages.length ||
      (n <= 5 && currentPage <= 4) ||
      (n >= pageCount - 5 && currentPage >= pageCount - 4) ||
      [currentPage - 1, currentPage, currentPage + 1].includes(n)
    ) {
      return (
        <span
          key={n}
          onClick={() => isCurrentPage(n) || onChange(n)}
          className={clsx(styles.pageNumber, isCurrentPage(n) ? styles.currentPage : styles.clickablePage)}
        >
          {n}
        </span>
      );
    }
    if (currentPage >= 4 && n === 2) {
      return renderDotz(PaginationInputPosition.LEFT);
    }

    if (currentPage <= pageCount - 4 && n === pageCount - 1) {
      return renderDotz(PaginationInputPosition.RIGHT);
    }

    return <></>;
  };

  return (
    <div className={styles.component} data-testid={testId} data-test-element="pagination">
      {renderArrowLeft()}
      {pages.map(n => renderPageNo(n))}
      {renderArrowRight()}
    </div>
  );
};
