import clsx from 'clsx';
import {FC} from 'react';
import {twMerge} from 'tailwind-merge';
import '../../../tailwind.utilities.css';
import {TestableElement} from '../../external/types';
import {Icon, IconProps, IconSize, IconSvg, IconVariant} from '../Icon/Icon';

export enum ButtonSecondaryVariant {
  GREEN = 'GREEN',
  RED = 'RED',
}

export enum ButtonSecondarySize {
  SM = 'SM',
  MD = 'MD',
}

export enum ButtonSecondaryWidth {
  FULL = 'FULL',
  FIT = 'FIT',
  BASE = 'BASE',
  INITIAL = 'INITIAL',
}

export type ButtonSecondaryConfig = {
  variant: ButtonSecondaryVariant;
  size: ButtonSecondarySize;
  width: ButtonSecondaryWidth;
};

export type ButtonSecondaryProps = Partial<ButtonSecondaryConfig> & {
  lIcon?: IconSvg | null;
  rIcon?: IconSvg | null;
  iconOptions?: Omit<IconProps, 'svg'>;
  stickyIcons?: boolean;
  text?: string;
  onClick?: () => unknown;
  className?: string;
  disabled?: boolean;
  loading?: boolean;
} & TestableElement;

type ButtonStyles = {
  button: string;
  icon: string;
  text: string;
};

const getStyles = (
  config: ButtonSecondaryConfig & {disabledOrLoading: boolean; hasText: boolean; stickyIcons: boolean},
): ButtonStyles => {
  const styles = {
    button: clsx({
      'relative outline-none focus-visible:ring-offset-2 focus-visible:ring-1 group whitespace-nowrap border-2 border-solid inline-flex justify-center items-center rounded-lg select-none focus-visible:outline focus-visible:outline-[3px]':
        true,
      'w-full': config.hasText && config.width === ButtonSecondaryWidth.FULL,
      'min-w-[200px]': config.hasText && config.width === ButtonSecondaryWidth.BASE,
      'w-fit': config.hasText && config.width === ButtonSecondaryWidth.FIT,
      'px-[16px]': config.hasText,
      'h-[40px]': config.size === ButtonSecondarySize.SM,
      'h-[48px]': config.size === ButtonSecondarySize.MD,
      'min-w-[40px]': config.size === ButtonSecondarySize.SM && config.width !== ButtonSecondaryWidth.BASE,
      'min-w-[48px]': config.size === ButtonSecondarySize.MD && config.width !== ButtonSecondaryWidth.BASE,
      'focus-visible:ring-primary-600 border-primary-500 hover:bg-primary-500 hover:border-transparent active:bg-primary-600 active:border-transparent':
        config.variant === ButtonSecondaryVariant.GREEN && !config.disabledOrLoading,
      'focus-visible:ring-red-600 border-red-500 hover:bg-red-500 hover:border-transparent active:bg-red-600 active:border-transparent':
        config.variant === ButtonSecondaryVariant.RED && !config.disabledOrLoading,
      'border-grey-300': config.disabledOrLoading,
      'pointer-events-none': config.disabledOrLoading,
    }),
    icon: clsx({
      'filter-grey-300': config.disabledOrLoading,
      'group-hover:filter-grey-0': !config.disabledOrLoading,
      'filter-primary-500': !config.disabledOrLoading && config.variant === ButtonSecondaryVariant.GREEN,
      'filter-red-500': !config.disabledOrLoading && config.variant === ButtonSecondaryVariant.RED,
    }),
    text: clsx('font-quicksand font-bold text-base', {
      grow: config.stickyIcons,
      'text-grey-300': config.disabledOrLoading,
      'group-hover:text-white': !config.disabledOrLoading,
      'text-primary-500': !config.disabledOrLoading && config.variant === ButtonSecondaryVariant.GREEN,
      'text-red-500': !config.disabledOrLoading && config.variant === ButtonSecondaryVariant.RED,
    }),
  };

  return styles;
};

export const ButtonSecondary: FC<ButtonSecondaryProps> = ({
  onClick = undefined,
  iconOptions = undefined,
  lIcon = null,
  rIcon = null,
  text = '',
  className = '',
  disabled = false,
  variant = ButtonSecondaryVariant.GREEN,
  size = ButtonSecondarySize.MD,
  width = ButtonSecondaryWidth.FIT,
  stickyIcons = false,
  loading = false,
  testId = undefined,
}) => {
  const disabledOrLoading = disabled || loading;
  const styles = (() => getStyles({variant, size, width, disabledOrLoading, hasText: text.length > 0, stickyIcons}))();
  const iconSize = size === ButtonSecondarySize.MD ? IconSize.LG : IconSize.MD;

  return (
    <button
      type="button"
      onClick={onClick}
      className={twMerge(clsx([styles.button, className]))}
      tabIndex={0}
      disabled={disabledOrLoading}
      data-test-element="button-secondary"
      data-testid={testId}
    >
      {(lIcon || (loading && !(!lIcon && rIcon))) && (
        <Icon
          className={clsx({[styles.icon]: true, 'mr-[8px]': text.length > 0 || !!rIcon, 'animate-spin': loading})}
          svg={(loading ? IconSvg.ROTATE_RIGHT : lIcon) as IconSvg}
          size={iconSize}
          variant={IconVariant.CONTOUR}
          {...iconOptions}
        />
      )}
      {text && <span className={styles.text}>{text}</span>}
      {(rIcon || (loading && !lIcon && rIcon)) && (
        <Icon
          className={clsx({
            [styles.icon]: true,
            'ml-[8px]': text.length > 0,
            'animate-spin': loading && !lIcon && rIcon,
          })}
          svg={loading && !lIcon && rIcon ? IconSvg.ROTATE_RIGHT : rIcon}
          size={iconSize}
          variant={IconVariant.CONTOUR}
          {...iconOptions}
        />
      )}
    </button>
  );
};
