import {FC, ReactNode} from 'react';
import clsx from 'clsx';
import {ButtonPrimary, ButtonPrimaryProps} from '../Button/ButtonPrimary';
import {ButtonTertiary, ButtonTertiaryProps} from '../Button/ButtonTertiary';
import {EmptyState} from '../EmptyState/EmptyState';
import {TestableElement} from '../../external/types';

export enum CardWidth {
  FIT = 'FIT',
  FULL = 'FULL',
}

export enum CardSize {
  SM = 'SM',
  MD = 'MD',
}

export enum CardDirection {
  HORIZONTAL = 'HORIZONTAL',
  VERTICAL = 'VERTICAL',
}

export type CardProps = {
  content?: ReactNode;
  bottomContent?: ReactNode;
  header: string;
  description: string;
  primaryButton?: Omit<ButtonPrimaryProps, 'className'>;
  secondaryButton?: Omit<ButtonTertiaryProps, 'className'>;
  size?: CardSize;
  direction?: CardDirection;
  width?: CardWidth;
  className?: string;
  contentClassName?: string;
  bottomContentClassName?: string;
} & TestableElement;

export const Card: FC<CardProps> = ({
  content = undefined,
  bottomContent = undefined,
  header,
  description,
  primaryButton = undefined,
  secondaryButton = undefined,
  size = CardSize.MD,
  direction = CardDirection.VERTICAL,
  width = CardWidth.FIT,
  className = '',
  contentClassName = '',
  bottomContentClassName = '',
  testId = undefined,
}) => {
  const styles = {
    component: clsx('flex shadow-xl max-w-[100%] p-8 items-center rounded-lg overflow-hidden', className, {
      'w-fit': width === CardWidth.FIT,
      'w-full': width === CardWidth.FULL,
      'flex-col text-center': direction === CardDirection.VERTICAL,
      'flex-row': direction === CardDirection.HORIZONTAL,
    }),
    contentBox: clsx(contentClassName, 'w-full flex justify-center', {
      'pb-4': size === CardSize.MD && direction === CardDirection.VERTICAL,
      'pb-2': size === CardSize.SM && direction === CardDirection.VERTICAL,
      'pr-6': direction === CardDirection.HORIZONTAL,
    }),
    bottomContent: clsx(bottomContentClassName, 'w-full flex flex-col items-center gap-[16px] mt-auto', {
      'pt-6': direction === CardDirection.VERTICAL,
      'pl-6': direction === CardDirection.HORIZONTAL,
      'pb-2': size === CardSize.SM && direction === CardDirection.VERTICAL,
      'pb-4': size === CardSize.MD && direction === CardDirection.VERTICAL,
    }),
    textContentBox: clsx('flex flex-col gap-y-4', {
      'gap-y-4': size === CardSize.MD,
      'gap-y-2': size === CardSize.SM,
    }),
    header: clsx('font-quicksand font-bold', {'text-2xl': size === CardSize.MD, 'text-xl': size === CardSize.SM}),
    description: clsx('font-roboto font-normal', {
      'text-base': size === CardSize.MD,
      'text-sm': size === CardSize.SM,
      overflow: direction === CardDirection.VERTICAL,
    }),
    buttonBox: clsx('flex items-center flex justify-end flex-grow', {
      'flex-col pt-6 gap-y-4': direction === CardDirection.VERTICAL,
      'flex-row-reverse pl-6 gap-x-6': direction === CardDirection.HORIZONTAL,
    }),
  };

  if (bottomContent && (primaryButton || secondaryButton)) {
    console.warn(
      'Symfonia: Komponent Card zawiera nadmiarowe właściwości: bottomContent oraz primaryButton i/lub secondaryButton. BottomContent zostanie zignorowany.',
    );
  }

  return (
    <div className={styles.component} data-test-element="card-component" data-testid={testId}>
      <div className={styles.contentBox}>{content || <EmptyState />}</div>
      {(header || description) && (
        <div className={styles.textContentBox}>
          <div className={styles.header}>{header}</div>
          <div className={styles.description} data-testid="card-description-box">
            {description}
          </div>
        </div>
      )}
      {primaryButton?.text || secondaryButton?.text ? (
        <div className={styles.buttonBox}>
          {primaryButton && <ButtonPrimary {...primaryButton} />}
          {secondaryButton && <ButtonTertiary {...secondaryButton} />}
        </div>
      ) : (
        <div className={styles.bottomContent}>{bottomContent}</div>
      )}
    </div>
  );
};
