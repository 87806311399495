import {FC, useEffect} from 'react';
import {Spinner, SpinnerProps, SpinnerSize} from '@symfonia/brandbook';

export const SpinnerWithCover: FC<SpinnerProps> = ({size = SpinnerSize.LG}) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);
  return (
    <div className="flex justify-center items-center fixed top-0 left-0 w-[100vw] h-[100vh] bg-white z-[10000] bg-opacity-75">
      <Spinner size={size}/>
    </div>
  );
};
