import {FormattedMessage} from 'react-intl';
import {CircularChart} from './CircularChart';
import {ReactNode} from 'react';
import {useLinearLimitCalculations} from '../LinearPackageLimit/linearPackageLimitHooks';
import {StatusColorEnum} from '../LinearPackageLimit/LinearPackageLimitType';

export enum ChartElement {
  AVAILABLE = 'available',
  USED = 'used',
  CAPACITY = 'capacity',
}

export const PACKAGES_COLORS = {
  NORMAL: {
    light: '#8CD28C',
    default: '#D9F0D9',
    dark: '#009A00',
  },
  EXCEEDED: {
    light: '#E98B97',
    default: '#F8D8DC',
    dark: '#DA3E52',
  },
  CRITICAL: {
    light: '#E7B282',
    default: '#F8E7D8',
    dark: '#DA893E',
  }
};

interface Props {
  chartData: { type: ChartElement, name: string; numberValue: number; stringValue?: string }[];
  width?: number;
  testId?: string;
  insideText?: ReactNode;
  titleElement: ReactNode;
  thresholds: [number, number, number];
  isNotActive: boolean;
}

export const CircularPackageLimit = ({chartData, insideText, width = 130, titleElement, testId, thresholds, isNotActive}: Props) => {
  const totalValue = chartData.filter(data => data.name === 'package')[0].numberValue;
  const usedValue = chartData.filter(data => data.name === 'used')[0].numberValue;
  const {barColor} = useLinearLimitCalculations({
    thresholds,
    usage: usedValue,
    limit: totalValue,
  });

  const circleColorsSet = {
    [StatusColorEnum.Critical]: {
      outerLineColor: PACKAGES_COLORS.CRITICAL.light,
      availableLeftColor: PACKAGES_COLORS.CRITICAL.default,
      percentageColor: PACKAGES_COLORS.CRITICAL.dark,
    },
    [StatusColorEnum.Exceeded]: {
      outerLineColor: PACKAGES_COLORS.EXCEEDED.light,
      availableLeftColor: PACKAGES_COLORS.EXCEEDED.default,
      percentageColor: PACKAGES_COLORS.EXCEEDED.dark,
    },
    [StatusColorEnum.Normal]: {
      outerLineColor: PACKAGES_COLORS.NORMAL.light,
      availableLeftColor: PACKAGES_COLORS.NORMAL.default,
      percentageColor: PACKAGES_COLORS.NORMAL.dark,
    },
    [StatusColorEnum.Inactive]: {
      outerLineColor: PACKAGES_COLORS.EXCEEDED.light,
      availableLeftColor: PACKAGES_COLORS.EXCEEDED.default,
      percentageColor: PACKAGES_COLORS.EXCEEDED.dark,
    },
  };

  const mapTypeToColorSet = {
    [ChartElement.AVAILABLE]: {
      [StatusColorEnum.Normal]: PACKAGES_COLORS.NORMAL.default,
      [StatusColorEnum.Critical]: PACKAGES_COLORS.CRITICAL.default,
      [StatusColorEnum.Exceeded]: PACKAGES_COLORS.EXCEEDED.default,
      [StatusColorEnum.Inactive]: PACKAGES_COLORS.EXCEEDED.default,
    },
    [ChartElement.USED]: {
      [StatusColorEnum.Normal]: PACKAGES_COLORS.NORMAL.dark,
      [StatusColorEnum.Critical]: PACKAGES_COLORS.CRITICAL.dark,
      [StatusColorEnum.Exceeded]: PACKAGES_COLORS.EXCEEDED.dark,
      [StatusColorEnum.Inactive]: PACKAGES_COLORS.EXCEEDED.dark,
    },
    [ChartElement.CAPACITY]: {
      [StatusColorEnum.Normal]: PACKAGES_COLORS.NORMAL.light,
      [StatusColorEnum.Critical]: PACKAGES_COLORS.CRITICAL.light,
      [StatusColorEnum.Exceeded]: PACKAGES_COLORS.EXCEEDED.light,
      [StatusColorEnum.Inactive]: PACKAGES_COLORS.EXCEEDED.light,
    }
  };

  return (
    <div className="flex shadow-lg rounded-xl w-full gap-[14px]">
      <div className={`max-w-[${width}px] p-2`}>
        <CircularChart total={totalValue} usedValue={usedValue} insideText={insideText} config={{
          percentageColor: circleColorsSet[isNotActive ? StatusColorEnum.Inactive : barColor].percentageColor,
          availableLeftColor: circleColorsSet[isNotActive ? StatusColorEnum.Inactive : barColor].availableLeftColor,
          outerLineColor: circleColorsSet[isNotActive ? StatusColorEnum.Inactive : barColor].outerLineColor
        }}/>
      </div>
      <div className="items-center mt-[18px]">
        <div>
          {titleElement}
        </div>
        <div className="mt-[8px]">
          {chartData.map(({type, name, numberValue, stringValue}) => (
            <span key={`${name}-${numberValue}`} className="inline-flex items-center gap-[6px]" data-testid={`${testId}-${name}`}>
              <div className="w-4 h-4 rounded" style={{backgroundColor: mapTypeToColorSet[type][isNotActive ? StatusColorEnum.Inactive : barColor]}}/>
              <FormattedMessage id={name}/>: {stringValue ?? numberValue}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};
