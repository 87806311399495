import {ToastProps} from './Toast';
import {useToastState} from './Toast.zustand';

export type SetToastProps = Omit<ToastProps, 'id'> & {id?: string};

export const setToast = ({id, ...toastProps}: SetToastProps) => {
  const uuid = crypto.randomUUID();
  useToastState.getState().setToast({...toastProps, id: id || uuid});

  return () => useToastState.getState().clearToast(id || uuid);
};

export const useToast = () => setToast;
