import React, {FC} from 'react';
import {Icon, IconColor, IconSize, IconSvg} from '@symfonia/brandbook';
import {LinearPackageLimitsGroup, LinearPackageLimitsGroupProps} from '../LinearPackageLimitsGroup/LinearPackageLimitsGroup';
import {IconWithTooltip} from '../IconWithTooltip/IconWithTooltip';
import {theme} from '../../theme';

export type IAccountLimits = {
  limitsEnable?: boolean;
  packageEndingContent?: React.ReactNode;
} & LinearPackageLimitsGroupProps;

export const AccountLimits: FC<IAccountLimits> = ({
                                                    limitsEnable,
                                                    enableInheritedBackground,
                                                    packageEndingContent,
                                                    thresholds,
                                                    ...packageLimitsProps
                                                  }) => {

  const {dateTo} = packageLimitsProps;

  return (
    dateTo && limitsEnable
      ? <IconWithTooltip
          tooltipWidth="auto"
          tooltipClass={'w-[260px]'}
          icon={{icon: IconSvg.ASSESSMENT, color: IconColor.GREY_500}}
          onClick={() => packageLimitsProps.redirect?.()}
          tooltipContent={
            <div>
              <LinearPackageLimitsGroup
                wrap={true}
                textColor={theme.palette.common.white} {...packageLimitsProps}
                enableInheritedBackground={false}
                thresholds={thresholds}
              />
              {packageEndingContent}
            </div>
            }
      />
      : <></>
  );
};
