import Popover from '@mui/material/Popover/Popover';
import clsx from 'clsx';
import dayjs from 'dayjs';
import {FactoryOpts} from 'imask';
import {debounce} from 'lodash';
import {FC, FocusEvent, MouseEvent, ReactNode, RefObject, useEffect, useMemo, useRef, useState} from 'react';
import '../../../tailwind.utilities.css';
import {CalendarDateRange, DateObj, getOffsetYear} from '../../external/elements/Calendar/Calendar.helper';
import {Subject} from '../../external/helpers/Subject';
import {DATE_DMY} from '../../external/types';
import {Calendar} from '../Calendar/Calendar';
import {Icon, IconSize, IconSvg} from '../Icon/Icon';
import {Input, InputProps, InputSize, InputWidth} from '../Input/Input';
import {fixDateValue, getIMaskConfig, isValidDateFormat, toDateObj, toDateStr} from './InputDate.helpers';

export {InputSize as InputDateSize, InputWidth as InputDateWidth} from '../Input/Input';

export type InputDateProps = Omit<
  InputProps<DATE_DMY>,
  | 'min'
  | 'max'
  | 'type'
  | 'leftIcon'
  | 'rightIcon'
  | 'onIconClick'
  | 'noOutline'
  | 'mask'
  | 'autocomplete'
  | 'clearable'
  | 'onBlur'
  | 'onFocus'
  | 'testElement'
> & {
  today?: Date;
  minDate?: Date | DateObj;
  maxDate?: Date | DateObj;
  calendarMinDate?: Date | DateObj;
  calendarMaxDate?: Date | DateObj;
  autofix?: boolean;
  revertToValue?: DATE_DMY;
  hasCalendar?: boolean;
  onFocus?: (event?: FocusEvent<HTMLInputElement>) => void;
  onBlur?: (event?: FocusEvent<HTMLInputElement>) => void;
  onCalendarYearToDisplayChange?: (year: number) => void;
  onCalendarMonthToDisplayChange?: (month: number) => void;
  onCalendarIconClick?: (event: MouseEvent<HTMLSpanElement>, inputEl?: HTMLInputElement) => void;
  additionalCalendarNavbarHeaderContent?: ReactNode;
};

export const InputDate: FC<InputDateProps & {componentRef?: RefObject<HTMLDivElement>}> = ({
  placeholder = '',
  label = '',
  today = dayjs().startOf('day').toDate(),
  disabled = false,
  isError = false,
  notification = '',
  defaultValue = undefined,
  value = undefined,
  onChange = () => undefined,
  onInput = () => undefined,
  size = InputSize.MD,
  width = InputWidth.BASE,
  minDate = getOffsetYear(today, -100),
  maxDate = getOffsetYear(today, 100),
  calendarMinDate = minDate,
  calendarMaxDate = maxDate,
  className = undefined,
  autofix = false,
  revertToValue = undefined,
  required = false,
  readonly = false,
  hasCalendar = true,
  onFocus = undefined,
  onBlur = undefined,
  onCalendarYearToDisplayChange = undefined,
  onCalendarMonthToDisplayChange = undefined,
  onCalendarIconClick = undefined,
  additionalCalendarNavbarHeaderContent = undefined,
  testId = undefined,
  componentRef = undefined,
}) => {
  const [isFocus, setIsFocus] = useState<boolean>(false);
  const focusCancelNextRef = useRef<boolean>(false);
  const focusRef = useRef(
    debounce((nextFocus: boolean, event?: FocusEvent<HTMLInputElement>) => {
      if (focusCancelNextRef.current) {
        focusCancelNextRef.current = false;
        return;
      }

      setIsFocus(prevFocus => {
        if (nextFocus && !prevFocus && onFocus) {
          onFocus(event);
        }

        if (!nextFocus && prevFocus && onBlur) {
          onBlur(event);
        }

        return nextFocus;
      });
    }, 100),
  );

  const focusSubjectRef = useRef<Subject<boolean>>(new Subject<boolean>());
  const ref = useRef<HTMLDivElement>(null);
  const [showCalendar, _setShowCalendar] = useState(false);
  const setShowCalendar = (nextShowCalendar: boolean) => {
    if (nextShowCalendar === false) {
      focusRef.current(true);
      requestAnimationFrame(() => focusSubjectRef.current.next(true));
    }
    _setShowCalendar(nextShowCalendar);
  };

  const [inputValue, setInputValue] = useState<DATE_DMY>(() => {
    const valueToConsider = defaultValue || value || '';
    if (!valueToConsider || !isValidDateFormat(valueToConsider)) {
      return '';
    }
    return valueToConsider.trim() as DATE_DMY;
  });

  const [maskConfig, setMaskConfig] = useState<FactoryOpts & {pattern: string}>(
    getIMaskConfig({value: inputValue.replace(/-/g, ''), minDate, maxDate}),
  );

  useEffect(() => {
    const nextMaskConfig = getIMaskConfig({value: inputValue.replace(/-/g, ''), minDate, maxDate});

    if (maskConfig.pattern !== nextMaskConfig.pattern) {
      setMaskConfig(nextMaskConfig);
    }
  }, [inputValue, minDate, maxDate]);

  const isControlledInput = (() => {
    if (defaultValue !== undefined && value !== undefined) {
      throw new Error('Symfonia:brandbook:Input: mutually exclusive parameters');
    }
    return value !== undefined;
  })();

  const updateInputValue = (nextValue: DATE_DMY) => {
    if (!isControlledInput) {
      setInputValue(nextValue);
    }
    onInput(nextValue);
  };

  useEffect(() => {
    if (isControlledInput) {
      setInputValue(value || '');
    }
  }, [value]);

  const selectedDate = useMemo(() => toDateObj(inputValue), [inputValue]);

  const onCalendarDateChange = (d: DateObj | CalendarDateRange): void => {
    const dateStr = toDateStr(d as DateObj);
    updateInputValue(dateStr);
    onChange(dateStr);
    setShowCalendar(false);
  };

  const onCalendarClose = () => {
    setShowCalendar(false);
  };

  const handleInputChange = (dateStr: DATE_DMY): void => {
    if (autofix) {
      const fixedValue = fixDateValue(dateStr, {minDate, maxDate});

      if (dateStr !== fixedValue) {
        updateInputValue(fixedValue);
      }

      onChange(fixedValue);
    } else {
      onChange(dateStr);
    }
  };

  return (
    <div
      data-testid={testId}
      data-test-element="input-date"
      className={clsx('inline-flex relative', {
        'w-full': width === InputWidth.FULL,
        'w-[285px]': width === InputWidth.BASE,
        'w-fit': width === InputWidth.FIT,
      })}
      ref={ref}
    >
      <Input<DATE_DMY>
        label={label}
        value={inputValue}
        disabled={disabled}
        focusSubject={focusSubjectRef.current}
        placeholder={
          placeholder && inputValue === '' && !isFocus ? (
            <span className="font-quicksand pl-[12px] pr-[32px] text-grey-500 truncate">{placeholder}</span>
          ) : undefined
        }
        inputClassName={clsx({'text-white': placeholder && inputValue === '' && !isFocus})}
        className={className}
        isError={isError}
        notification={notification}
        size={size}
        width={width}
        rightIcon={hasCalendar ? IconSvg.CALENDAR_TODAY : undefined}
        onIconClick={(event, inputEl) => {
          setShowCalendar(true);
          if (onCalendarIconClick) onCalendarIconClick(event, inputEl);
        }}
        onIconMouseDown={() => {
          if (isFocus) {
            focusCancelNextRef.current = true;
          }
        }}
        onInput={updateInputValue}
        onChange={handleInputChange}
        mask={maskConfig}
        required={required}
        readonly={readonly}
        testElement="input-date-input"
        onFocus={event => {
          focusRef.current(true, event);
        }}
        onBlur={event => {
          focusRef.current(false, event);
        }}
        componentRef={componentRef}
      />
      {revertToValue && /\d{2}-\d{2}-\d{4}/.test(revertToValue) && (
        <Icon
          svg={IconSvg.UNDO}
          size={IconSize.MD}
          className={clsx('z-[1] select-none absolute right-[44px] top-[calc(50%-10px)]', {
            'filter-primary-500 cursor-pointer': !disabled,
            'filter-grey-300 pointer-events-none cursor-default': disabled,
          })}
          onClick={() => updateInputValue(revertToValue)}
        />
      )}

      <Popover
        open={showCalendar}
        onClose={onCalendarClose}
        anchorEl={ref.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        PaperProps={{
          style: {
            width: '320px',
            height: '320px',
            background: 'transparent',
            boxShadow: 'none',
          },
        }}
      >
        <Calendar
          className="drop-shadow-lg"
          minDate={calendarMinDate}
          maxDate={calendarMaxDate}
          today={today}
          onChange={onCalendarDateChange}
          selected={selectedDate}
          onYearToDisplayChange={onCalendarYearToDisplayChange}
          onMonthToDisplayChange={onCalendarMonthToDisplayChange}
          additionalNavbarHeaderContent={additionalCalendarNavbarHeaderContent}
          testElement="input-date-calendar"
        />
      </Popover>
    </div>
  );
};
