import 'reflect-metadata';
import {createRoot} from 'react-dom/client';
import * as buildInfo from '../../../buildInfo/.json';

import {isLocalDev} from './modules/common/helpers/helperFunctions';
import Bootstrap from './Bootstrap';

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    appBar: true;
  }
}

if (!isLocalDev) {
  console.info('Build Info:', buildInfo);
  console.info('Info 221001-1');
}

const container = document.getElementById('root');
if (!container) throw new Error('Container not found');
const root = createRoot(container);

root.render(<Bootstrap/>);
