import {IMask} from "react-imask";
import {DateObj, dateObjToDate, dateToYearMonthString, dateToYearString, getOffsetDate, isAfterDate, isBeforeDate} from "../InputDateRange/Calendar.helper";
import {FactoryOpts} from "imask/esm/index";
import {DATE_Y, DATE_MY} from "../../../types";
import {isDate} from "lodash";

export const toDateMonthAndYearStr = ({year, month}: DateObj): DATE_MY => {
    return `${`0${month}`.slice(-2)}-${year}` as DATE_MY;
  };
  
  export const toDateYearStr = ({year}: DateObj): DATE_Y => {
    return `${year}` as DATE_Y;
  };

  type fixDateMonthAndYearValueFn = {
    (nextValue: string, _: {minDate: Date | DateObj; maxDate: Date | DateObj; dayOffset?: number}): DATE_MY;
  };
  
  type fixDateYearValueFn = {
    (nextValue: string, _: {minDate: Date | DateObj; maxDate: Date | DateObj; dayOffset?: number}): DATE_Y;
  };

  export const fixDateMonthAndYearValue: fixDateMonthAndYearValueFn = (nextValue, {minDate, maxDate, dayOffset = 1}) => {
    const valueFix = nextValue.substring(0, 7);
    const minDateRef = dateObjToDate(minDate);
    const maxDateRef = dateObjToDate(maxDate);
  
    const [year, month] = valueFix.split('-').map(s => parseInt(s, 10) || 1);
    const nextDateMin = new Date(year, month - 1, 1);
    const nextDateMax = new Date(year, month, 0);
    let nextDate = nextDateMin;
  
    if (isBeforeDate(nextDateMax, minDateRef)) {
      nextDate = getOffsetDate(minDateRef, dayOffset);
    }
  
    if (isAfterDate(nextDateMin, maxDateRef)) {
      nextDate = getOffsetDate(maxDateRef, -dayOffset);
    }
  
    return dateToYearMonthString(nextDate) as DATE_MY;
  };
  
  export const fixDateYearValue: fixDateYearValueFn = (nextValue, {minDate, maxDate, dayOffset = 1}) => {
    const valueFix = nextValue.substring(0, 4);
    const minDateRef = dateObjToDate(minDate);
    const maxDateRef = dateObjToDate(maxDate);
  
    const year = parseInt(valueFix, 10);
    const nextDateMin = new Date(year, 0, 1);
    const nextDateMax = new Date(year + 1, 0, 0);
    let nextDate = nextDateMin;
  
    if (isBeforeDate(nextDateMax, minDateRef)) {
      nextDate = getOffsetDate(minDateRef, dayOffset);
    }
  
    if (isAfterDate(nextDateMin, maxDateRef)) {
      nextDate = getOffsetDate(maxDateRef, -dayOffset);
    }
  
    return dateToYearString(nextDate) as DATE_Y;
  };

  export const getIMaskConfigMonthAndYear = ({
    value,
    minDate,
    maxDate,
  }: {
    value: string;
    minDate: Date | DateObj;
    maxDate: Date | DateObj;
  }): FactoryOpts & {pattern: string} => {
    const minDateRef = dateObjToDate(minDate);
    const maxDateRef = dateObjToDate(maxDate);
  
    const pattern = (() => {
      if (value.length <= 2) {
        return 'm-`Y';
      }
  
      return 'm{-}`Y';
    })();
  
    return {
      mask: Date,
      pattern,
      blocks: {
        m: {
          mask: IMask.MaskedRange,
          from: 1,
          to: 12,
          maxLength: 2,
          placeholderChar: 'M',
        },
        Y: {
          mask: IMask.MaskedRange,
          placeholderChar: 'R',
          from: minDateRef.getFullYear() - 100,
          to: maxDateRef.getFullYear() + 100,
        },
      },
      autofix: true,
      lazy: false,
      overwrite: true,
      min: minDateRef,
      max: maxDateRef,
      parse: (str: string) => {
        const yearMonth = str.split('-');
        return new Date(parseInt(yearMonth[1], 10), parseInt(yearMonth[0], 10) - 1, 1);
      },
      format: (date: Date | null) => {
        if (!isDate(date)) {
          return '';
        }

        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();

        return [month >= 10 ? month : `0${month}`, year].join('-') as DATE_MY;
      },
    };
  };
  
  export const getIMaskConfigYear = ({
    value,
    minDate,
    maxDate,
  }: {
    value: string;
    minDate: Date | DateObj;
    maxDate: Date | DateObj;
  }): FactoryOpts => {
    const minDateRef = dateObjToDate(minDate);
    const maxDateRef = dateObjToDate(maxDate);const pattern = (() => {
      return 'Y';
    })();
  
    return {
      mask: Date,
    pattern,
    blocks: {
      Y: {
        mask: IMask.MaskedRange,
        placeholderChar: 'R',
        from: minDateRef.getFullYear() - 100,
        to: maxDateRef.getFullYear() + 100,
      },
    },
    autofix: true,
    lazy: false,
    overwrite: true,
    min: minDateRef,
    max: maxDateRef,
    parse: (str: string) => {
      return new Date(parseInt(str, 10), 0, 1);
    },
    format: (date: Date | null) => {
      return date ? date.getFullYear().toString() as DATE_Y : '';
    },
    };
  };
  