import {FC, PropsWithChildren, useCallback, useEffect, useState} from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/pl';
import {InputDate, InputDateWidth, InputWidth, Label} from '@symfonia/brandbook';
import {DATE_DMY} from '../../../../../../libs/brandbook/src/external/types';
import {europeanDateFormatter} from 'libs/symfonia-ksef-components/src/helpers/europeanDateFormatter';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
export interface DatePickerProps extends PropsWithChildren {
  setDate: (value?: {from?: Date; to?: Date; specific?: Date}) => void;
  startDate?: Date;
  specificDate?: Date;
  endDate?: Date;
  singleDateLabel?: string;
  rangeStartDateLabel: string;
  singleDateInputLabel: string;
  rangeDateInputLabel?: string;
  rangeDateFromLabel?: string;
  rangeDateToLabel?: string;
  setValidationError?: (hasError: boolean) => void;
  testId?: string;
}

export const DatePicker: FC<DatePickerProps> = ({
  startDate,
  endDate,
  setDate,
  specificDate,
  singleDateLabel,
  rangeStartDateLabel,
  singleDateInputLabel,
  rangeDateInputLabel,
  rangeDateFromLabel,
  rangeDateToLabel,
  setValidationError,
  testId,
}) => {
  const [hasError, setHasError] = useState(false);
  const specificDateValue = specificDate && dayjs(specificDate)?.format('DD-MM-YYYY') as DATE_DMY;

  const onChangeClearValidation = useCallback(() => {
    setValidationError?.(false);
    setHasError(false);
  }, [setValidationError]);

  useEffect(() => {
    if (!startDate && !endDate) return;
    if (!dayjs(startDate).isBefore(dayjs(endDate))) {
      setValidationError?.(true);
      setHasError(true);
      return;
    }
    onChangeClearValidation();
  }, [startDate, endDate]);

  const handleOnChange = (date: DATE_DMY, type: 'FROM' | 'TO') => {
    const newDate = europeanDateFormatter(date);
    setDate({from: type === 'FROM' ? newDate : startDate, to: type === 'TO' ? newDate : endDate, specific: undefined});
  };

  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'pl'} />
      <InputDate
        label={singleDateLabel}
        placeholder={singleDateInputLabel}
        width={InputWidth.FULL}
        value={specificDateValue || '' as DATE_DMY}
        onChange={newDate => {
          const europeanDate = europeanDateFormatter(newDate);
          europeanDate !== specificDate ? setDate({specific: europeanDate}) : setDate(undefined);
          setHasError(false);
          setValidationError?.(false);
        }}
        testId={`${testId}-SpecificDate`}
      />
      <div className="flex flex-col mt-2 gap-[8px]">
        {rangeDateInputLabel && <Label text={rangeDateInputLabel}/>}
        <div className="flex gap-[8px]">
          <InputDate
            width={InputDateWidth.FIT}
            isError={hasError}
            placeholder={rangeDateFromLabel || 'Od'}
            onChange={date => handleOnChange(date, 'FROM')}
            className="mb-[8px]"
            value={startDate !== undefined ? (dayjs(startDate)?.format('DD-MM-YYYY') as DATE_DMY) : ('' as DATE_DMY)}
            testId={`${testId}-DateFrom`}
            maxDate={dayjs().toDate()}
          />
          <InputDate
            width={InputDateWidth.FIT}
            isError={hasError}
            placeholder={rangeDateToLabel || 'Do'}
            onChange={date => handleOnChange(date, 'TO')}
            className="mb-[8px]"
            value={endDate !== undefined ? (dayjs(endDate)?.format('DD-MM-YYYY') as DATE_DMY) : ('' as DATE_DMY)}
            testId={`${testId}-DateTo`}
            minDate={startDate ? dayjs(startDate).toDate() : undefined}
          />
        </div>
      </div>
    </div>
  );
};
